.checkboxRow {
    height: 30px !important;
    display: flex;
    align-items: center;
    gap: 15px;
}

.checkboxColContainer {
    display: flex;
    justify-content: space-between;
}

.formRow {
    margin-top: 15px;
    margin-bottom: 10px;
}