.applicationContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
}

.applicationContainer main {
    width: 100%;
    background-color: white;
    overflow: hidden;
}