.controlContainer {
    height: 70px;
}

.form {
    margin: 10px;
    height: calc(100% - 20px);
}

.error {
    font-size: 11px;
    color: red;
    font-weight: bold;
    margin-left: 2px;
}

.serverErrorContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
}

.serverErrorContainer > div {
    font-size: 14px;
    color: white;
    padding: 5px;
    background-color: red;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}