* {
    margin: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

body {
    font-family: 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #373d44;
    overflow: hidden;
}

.header-form-layout {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    user-select: none;
    pointer-events: none;
}

.footer-form-layout {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    gap: 10px;
}

.label {
    font-size: 12px;
    margin: 1px;
    color: black;
}

.label.required:after {
    content: '*';
    margin-left: 2px;
    font-size: 16px;
    color: red;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: #eeeeee;
}

input {
    line-height: initial;
}


.list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.e-pager .e-pagerexternalmsg {
    display: none !important;
}

.server-error-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
}

.server-error-container > div {
    font-size: 12px;
    color: white;
    padding: 5px;
    background-color: red;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.field-error {
    font-size: 11px;
    color: red;
    font-weight: bold;
    margin-left: 2px;
}

.input-container {
    height: 70px;
}

.input-textarea-container {
    margin-bottom: 10px;
}

.input-container.checkbox {
    height: 25px !important;
    display: flex;
    align-items: center;
    gap: 15px;
}

.input-container.checkbox.end {
    justify-content: end;
}

.toast-body-class {
    font-size: 12px
}

.default-form-layout-header {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: black;
    height: 100%;
    width: 100%;
    margin: 0 10px;
    font-weight: 500;
    user-select: none;
}

.default-form-layout-footer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.default-form-layout-container {
    padding-top: 10px;
}

.target-form-container {
    padding: 10px;
}


.syncfusion-control-input-container {

}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    font-family: inherit;
}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    border: 1px solid #acacac !important;
    border-radius: 2px !important;
}

.syncfusion-control-input-container input.e-input, .syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input {
    padding-left: 5px;
}

.syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input {
    min-height: 18px;
}

.syncfusion-control-input-container input.e-input, .syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input, .syncfusion-control-input-container .e-input-group.e-input-focus input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper.e-input-focus input.e-input {
    padding-left: 5px !important;
}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    margin-bottom: 0;
}

.syncfusion-control-input-container .e-input-group .e-input-group-icon:last-child, .syncfusion-control-input-container .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    margin-right: 5px;
}


#target-tabs .e-toolbar {
    text-align: center;
}

.image-uploader .e-upload .e-file-select-wrap, .image-uploader .e-upload.e-control, .image-uploader .e-upload .e-file-select, .image-uploader .e-upload .e-file-drop {
    display: none;
}

.image-uploader .e-upload {
    border: none;
}

.image-uploader .e-upload .e-upload-files {
    border-top: 1px solid #acacac;
}

.works-list-container .e-grid .e-gridheader {
    display: none;
}

.works-list-container .e-grid .e-content {
    border-top: 1px solid #c8c8c8;
}