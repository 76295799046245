.sidebarItem {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 2px 0;
}

.sidebarItem a {
    color: #eeeeee;
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    flex: 1;
    line-height: 40px;
}

.sidebarItem a:hover {
    color: white !important;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
}

.sidebarItem a svg {
    color: white;
    margin: 0 10px;
}

.indent {
    padding-left: 5px;
}

.active {
    color: white !important;
    background: rgba(255, 255, 255, .2) !important;
    border-radius: 2px;
}

.active svg {
    color: #7ac834 !important;
}