.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    height: 35px;
    border-bottom: 1px solid #acacac;
    background-color: #eeeeee;
}

.content {
    height: calc(100% - 75px);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.contentFw {
    height: calc(100% - 35px);
}

.footer {
    height: 40px;
}

.footerBottom {
    border-top: 1px solid #acacac;
}

.footerTop {
    border-bottom: 1px solid #acacac;
}

.formLoader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .1);
    cursor: progress;
    z-index: 1001;
}

.disabled {
    overflow: hidden;
}