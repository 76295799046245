.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: red;
}