.container {
    position: relative;
    height: 70px;
}


.container input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #373d44;
    border: none;
    border-bottom: 2px solid lightgreen;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.container input:disabled {
    cursor: not-allowed;
}

.container label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #373d44;
    pointer-events: none;
    transition: .5s;
}

.container input:focus ~ label,
.container input[data-validate='valid'] ~ label {
    top: -20px;
    left: 0;
    font-size: 12px;
}

.container input:-webkit-autofill,
.container input:-webkit-autofill:hover,
.container input:-webkit-autofill:focus,
.container input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.container input[data-validate='valid'] {
    border-color: #7ac834;
}

.container input[data-validate='invalid'] {
    border-color: red;
}


.error {
    font-size: 11px;
    color: red;
    font-weight: bold;
    margin: 2px 0;
}