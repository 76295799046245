.formRow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}