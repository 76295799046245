.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container img {
    width: 300px;
    pointer-events: none;
}