.button[data-role='button'] {
    position: relative;
    min-width: 100px;
    border: none;
    border-radius: 2px;
    margin: 0 2px;
    background-color: #7ac834;
    color: white;
    height: 30px;
    font-size: 14px;
    text-align: right;
}

.button[data-role='button']:hover {
    outline: 1px solid lightblue;
}

.buttonIconPadding {
    padding-left: 25px;
}

.icon {
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 14px;
}

.icon svg {
    color: white;
}


.button[data-role='tab'] {
    width: 100%;
    border: none;
    border-radius: 2px;
    background: #d8d8d8;
    color: #373d44;
    height: 30px;
    font-size: 14px;
    margin: 0 2px;
    white-space: nowrap;
}

.button[data-role='tab']:hover {
    outline: 1px solid lightblue;
}

.active {
    border: 2px solid #6e8196 !important
}

.center{
    text-align: center !important;
}