.container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.container > div {
    width: calc(100% / 3 - 20px);
    height: 60px;
}