.cropFormContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cropFormContainer > div:first-child {
    height: calc(100% - 40px);
    padding: 5px;
}

.cropFormContainer > div:last-child {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}