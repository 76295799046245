.pollQuestionListContainer {
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 1px;
    position: relative;
    display: grid;
    grid-template-rows: 35px 35px calc(100% - 70px);
    height: 373px;
    border-radius: 2px;
}

.pollQuestionListContainer > div:last-child {
    position: relative;
    overflow: auto;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    position: absolute;
}

.toolbarContainer {
    border-bottom: 1px solid #acacac;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.tableHeaderContainer {
    border-bottom: 1px solid #acacac;
    display: flex;
}

.tableHeaderContainer > div:not(:last-child) {
    border-right: 1px solid #acacac;
}

.tableHeaderContainer > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbarBtn {
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: transparent;
    display: flex;
    gap: 5px;
    height: 29px;
    align-items: center;
}

.toolbarBtn > span:first-child {
    color: #7ac834;
    font-size: 18px;
}

.toolbarBtn > span:last-child {
    font-size: 13px;
}

.toolbarBtn:disabled {
    cursor: not-allowed;
}


.table {
    width: 100%;
}

.table tr {
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    font-size: 14px;
}

.table tr > td:not(:last-child) {
    border-right: 1px solid #eaeaea;
    position: relative;
}

.table tr {
    height: 30px;
}

.table td svg {
    font-size: 20px;
    cursor: pointer;
}

.edit {
    color: #7ac834;
}

.reset {
    color: red;
}