.formRow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}

.htmlEditorRow {
    display: flex;
}

.htmlEditorRow > div:first-child {
    width:50%;
    margin-right: 10px;
}

.htmlEditorRow > div:last-child {
    width:50%;
    margin-left: 10px;
}

.contentContainer {
    width: 100%;
    height: 450px;
    overflow: auto;
    margin-top: 10px;
    border: 1px solid #acacac;
}

.content {
    width: 100%;
    height: 100%;
    padding:5px;
}

.dark {
    background-color: #53565A;
}