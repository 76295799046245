.toggleSidebarItem {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 2px 0;
}

.toggleSidebarItem a {
    color: #eeeeee;
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    flex: 1;
    line-height: 40px;
    cursor: pointer;
    position: relative;
}

.toggleSidebarItem a:hover {
    color: white !important;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
}

.toggleSidebarItem a svg {
    color: white;
    margin: 0 10px;
}

.caretIconContainer {
    position: absolute;
    right: 5px;
}

.childrenItemsContainer {
    margin-left: 25px;
}

.active span:first-child svg {
    color: #7ac834 !important;
}

.active {
}