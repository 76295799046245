.formRow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}

.htmlEditorRow {
    display: flex;
}

.htmlEditorRow > div:first-child {
    width: 50%;
    margin-right: 10px;
}

.htmlEditorRow > div:last-child {
    width: 50%;
    margin-left: 10px;
}

.contentContainer {
    width: 100%;
    height: 450px;
    overflow: auto;
    margin-top: 10px;
    border: 1px solid #acacac;
}

.content {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.radioButtonsContainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.radioButtonsContainer > div {
    margin: 10px 0;
}

.imageViewSection {
    width: inherit;
    margin-top: 10px;
}

.imageViewSection > img {
    width: 100%;
    cursor: pointer;
}

.redirect {
    position: absolute;
    right: 5px;
    font-size: 18px;
    color: #7ac834;
    cursor: pointer;
}

.relative {
    position: relative;
}