.input {
    width: 100%;
    padding: 5px 5px;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #acacac;
    border-radius: 2px;
    color: black;
}

.input:focus-visible {
    outline: none;
    border-color: #36ceff;
}

.input::placeholder {
    color: darkgray;
}

.input:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}


.input[type='checkbox'] {
    width: 18px;
    height: 18px;
}
