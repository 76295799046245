.container {
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 1px;
    position: relative;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.table {
    width: 100%;
}

.table tr:first-child {
    height: 35px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #acacac;
}

.table tr:not(:first-child) {
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    font-size: 14px;
}

.table tr:not(:first-child) > td:not(:last-child) {
    border-right: 1px solid #eaeaea;
    position: relative;
}

.table tr:first-child > th:not(:last-child) {
    border-right: 1px solid #acacac;
}

.table tr {
    height: 30px;
}

.table td svg {
    font-size: 20px;
    cursor: pointer;
}

.edit {
    color: #7ac834;
}

.reset {
    color: red;
}