.textArea {
    width: 100%;
    padding: 5px 5px;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #acacac;
    border-radius: 2px;
    color: black;
    min-height: 100px;
}


.textArea:focus-visible {
    outline: none;
    border-color: #36ceff;
}

.textArea::placeholder {
    color: darkgray;
}

.textArea:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}
