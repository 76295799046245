.header {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: black;
    height: 100%;
    width: 100%;
    margin: 0 10px;
    font-weight: 500;
    user-select: none;
}

.footer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 15px;
}

.container {
    padding-top: 10px;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.controlContainer {
    margin: 10px 0;
}

.checkContainer {
    display: flex;
    align-items: center;
    gap: 15px;
}

