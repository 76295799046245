.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.permissionRow {
    margin: 10px 0;
    font-size: 14px;
}

.permissionLabel {
    margin: auto;
}