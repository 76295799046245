.dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 95%;
    height: 95%;
    background-color: white;
    border-radius: 2px;

    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1001;
}


@-webkit-keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    100% {
        transform: scale(1);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}