.progressBarToast {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    cursor: default;
    direction: ltr;
    z-index: 10001;
    position: fixed;
    top: 10px;
    right: 20px;
    width: 250px;
    height: 55px;
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.25, 1.35);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.active {
    transform: translateX(0);
}

.progressBar {
    height: 20px;
    width: 90%;
    background-color: #e0e0de;
    border-radius: 2px;
    position: relative;
}

.progressBarFiller {
    height: 100%;
    background-color: lightgreen;
    border-radius: inherit;
    text-align: right;
    transition: width 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: end;
}

.progressBarLabel {
    color: black;
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    width: 20px;
}