.uploaderSelectAreaContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #acacac;
    border-radius: 2px;
}

.uploaderHeader {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    padding: 0 5px;
    height: 35px;
    align-items: center;
    position: relative;
}

.uploaderDropArea {
    padding: 5px;
}

.uploaderDropArea > div {
    height: 50px;
    border: 1px dashed black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.imageViewSection {
    width: inherit;
    margin-top: 10px;
}

.imageViewSection > img {
    width: 100%;
    cursor: pointer;
}