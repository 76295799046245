.chartContainer {
    flex: 1;
    height: 100%;
    position: relative;
}

.loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.2);
    z-index: 1001;
}