.btn {
    font-size: 14px;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 500;
}

.btn[data-type='default'] {
    background-color: #7ac834;
}

.btn[data-type='warning'] {
    background-color: #c73535;
}

.btn[data-type='back'] {
    background-color: #138496;
}

.btn:disabled {
    cursor: not-allowed;
}