.locationTargetInputContainer {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.locationTargetInputContainer > div {
    position: relative;
    width: 100%;
}

.locationTargetInput {
    width: 100%;
    padding: 5px 27px;
    font-size: 13px;
    border: 1px solid #acacac;
    border-radius: 2px;
    color: black;
    cursor: default;
    background-color: white;
}

.editIconSpan {
    position: absolute;
    left: 0;
    height: 28px;
    width: 24px;
    border-right: 1px solid #acacac;
    border-radius: 3px;
}

.editIconSpan svg {
    position: absolute;
    left: 5px;
    top: 6px;
    cursor: pointer;
    color: #138496;
}

.removeIconSpan {
    position: absolute;
    right: 0;
    height: 28px;
    width: 24px;
    border-left: 1px solid #acacac;
    border-radius: 3px;
}

.removeIconSpan svg {
    position: absolute;
    right: 5px;
    top: 6px;
    cursor: pointer;
    color: red;
}