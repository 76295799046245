.targetHeader {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    padding: 0 5px;
    height: 25px;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #acacac;
}

.targetHeader svg {
    font-size: 20px;
    cursor: pointer;
    color: #7ac834;
    position: absolute;
    top: 2px;
    right: 5px;
}