.section[data-role='tab'] {
    display: flex;
    flex: 1;
    width: 100%;
}

.section[data-role='button'] {
    display: flex;
}

.separator {
    width: 1px;
    margin: 0 3px;
    background-color: darkblue;
}