.selectorsContainer {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    border-bottom: 1px solid darkgray;
}

.selectorsContainer > div:first-child {
    min-width: 100px;
    display: flex;
    align-items: center;
}

.exportBtn {
    border: 1px solid #acacac;
    font-size: 15px;
    border-radius: 2px;
    background-color: white;
    padding: 2px 5px;
}

.exportBtn:hover {
    border-color: #76B82A;
}

.exportBtn > span:first-child {
    color: #76B82A;
    margin-right: 5px;
}

.exportBtn > span:last-child {
    color: #495057;
}

.selectorsContainer > div:last-child {
    width: 500px;
}

.selectorsContainer > div:last-child > div {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 40px;
    margin: 5px 0;
}

.selectorLabel {
    color: black;
    font-size: 14px;
}