.sidebar {
    min-width: 300px;
    background-color: #373d44;
    display: grid;
    grid-template-rows: 50px calc(100% - 100px) 50px;
}

.logo {
    width: 120px;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sidebar > div:first-child {
    background-color: #23272c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar > div:nth-child(2) {
    margin: 10px 0;
    overflow: auto;
}

.sidebar > div:last-child {
    display: flex;
    align-items: center;
    border-top: 1px solid #acacac;
}

.userLogo {
    margin: 0 10px;
    cursor: pointer;
}

.userLogo svg {
    color: #7ac834;
}

.userName {
    font-size: 16px;
    color: white;
    cursor: pointer;
    flex: 1;
}

.logOut {
    margin: 0 10px;
    padding-top: 5px;
}

.logOut svg {
    font-size: 20px;
    color: red;
    cursor: pointer;
}