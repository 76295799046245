.loginContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer > div {
    width: 500px;
    background-color: white;
    border-radius: 5px;
    padding: 30px;
    position: relative;

    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.loginContainer > div > div:first-child {
    text-align: center;
}

.loginContainer > div > div:first-child img {
    width: 65%;
    pointer-events: none;
}

.loginContainer > div > div:nth-child(4) {
    text-align: right;
}

.loginContainer > div > div:nth-child(4) a {
    color: #acacac;
    text-decoration: none;
    font-size: 13px;
}

.loginContainer > div > div:nth-child(4) a:hover {
    color: #7ac834;
}

.serverErrorContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
}

.serverErrorContainer > div {
    font-size: 14px;
    color: white;
    padding: 5px;
    background-color: red;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.loginContainer > div > div:nth-child(2) {
    font-size: 30px;
    color: #373d44;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding: 25px 0 25px 0;
}

.controlContainer {
    margin: 20px 30px;
}


@-webkit-keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    100% {
        transform: scale(1);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

.buttonContainer {
    text-align: center;
    margin: 25px 0;
}

.buttonContainer button {
    width: 60%;
    border: none;
    border-radius: 2px;
    padding: 7px 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    background-color: #7ac834;
    position: relative;
    font-size: 16px;
}

.buttonContainer button:disabled {
    cursor: not-allowed;
}

.buttonContainer button:hover {
    outline: 1px solid darkgray;
}

.loadingSpan {
    position: absolute;
    left: 10px;
    top: 8px;
    font-size: 15px;
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}